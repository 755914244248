<!-- 解约 -->
<template>
    <div class="container">
        <div class="container-content">

          <div class="orderNodata" v-if="equityInList.length==0">
            <img src="https://cdn.qiumo.net.cn/allsmarketmaterial/taiyue_wxgzh/nodata.png"></img>
            <div>您还没有相关的订单哦</div>
          </div> 

            <div class="content-home-list" v-else>
                <div class="home-list-item" v-for="(item, index) in equityInList" :key="index" v-if="item.item_long_name == '视听甄选会员'">
                    <div class="item-top">
                        <div class="top-title">
                            <img src="https://cdn.qiumo.net.cn/allsmarketmaterial/lky_gzh/ceshi-refund.png">
                            <span>{{ item.item_long_name }}</span>
                        </div>
                        <div class="top-price">
                            <span>{{item.item_price}}元/月</span>
                        </div>
                    </div>
                    <div class="item-bottom">
                        <div class="bottom-time">
                            <span>开通时间 {{ item.sign_time }}</span>
                        </div>
                        <div class="bottom-btn">
                            <button @click="unsignClick(item.item_name)">解约</button>
                        </div>
                    </div>
                </div>

                <div class="home-list-item" v-for="(item, index) in equityInList" :key="index" v-if="item.item_long_name == 'AI兔车主会员'">
                    <div class="item-top">
                        <div class="top-title">
                            <img src="https://cdn.qiumo.net.cn/allsmarketmaterial/lky_gzh/ceshi2-refund.png">
                            <span>{{ item.item_long_name }}</span>
                        </div>
                        <div class="top-price">
                            <span>{{item.item_price}}元/月</span>
                        </div>
                    </div>
                    <div class="item-bottom">
                        <div class="bottom-time">
                            <span>开通时间 {{ item.sign_time }}</span>
                        </div>
                        <div class="bottom-btn">
                            <button @click="unsignClick(item.item_name)">解约</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <v-alert
      		:visibility.sync="alert.visibility"
          :title="alert.title"
          :message="alert.message"
          :rulestype="alert.rulestype"
          @handleSubmit="handleSubmit"
          />

    </div>
</template>
  
  <script>
  import { record ,alipayUnsign } from "@/api/lky_gzh/index.js";
  import { mapMutations,mapGetters } from "vuex";
  import VAlert from "./record-alert/alert.vue";
  
  export default {
    components: {
      VAlert
    },
    data() {
      return {
        alert: {
          visibility: false,
          title: "",
          message: "",
        },
        equityInList:[],
        equityEndList:[],
        page: [1,1],
        orderStatus:'订购中',
        currentIndex:0,
        buyItemStatusTip: '', 
      };
    },
    computed: {
      ...mapGetters(["lky_token", "lky_user_info" ,"lky_wechat_open_id"]),
    },
    created() {
      this.recordList();
    },
    methods: {
      async recordList() {
        this.$toast.loading({
          duration: 0,
          forbidClick: true,
          message: "加载中...",
        });
        await record(this.lky_token,{ 'page': this.page[this.currentIndex], 'data_type': 'order', 'order_status': this.orderStatus }).then(res =>{
        if (res.code == 0) {
          this.pageCount = res.pages;
          if(this.page[this.currentIndex] == 1){
            if(this.currentIndex == 0){
              this.equityInList = res.record;
            }else if(this.currentIndex == 1){
              this.equityEndList = res.record;
            }
          }else{
            if(this.currentIndex == 0){
              this.equityInList = this.equityInList.concat(res.record);
            }else if(this.currentIndex == 1){
              this.equityEndList = this.equityEndList.concat(res.record);
            }
          }
          this.$toast.clear();
        } else {
            this.$toast.clear();
        }}).catch(err =>{
          this.$toast.clear();
          this.$router.replace({
            path: "/fz_taiyue/receive/login",
            query: {
              wechat_open_id: this.taiyue_wechat_open_id,
            },
          });
        })
      },

      unsignClick(name){
        this.$toast.loading({
          duration: 0,
          forbidClick: true,
          message: "加载中...",
        });
        alipayUnsign(this.lky_token,{'item_name': name}).then((res)=>{
          if (res.code == 0) {
            this.buyItemStatusTip = '解约成功';
            this.exchangeStatus = true;
            this.alert = {
              visibility: true,
              message: res.message,
              title: this.buyItemStatusTip,
            };
          } else {
              this.buyItemStatusTip = '解约失败';
              this.alert = {
                visibility: true,
                message: res.message,
                title: this.buyItemStatusTip,
              };
          }
          setTimeout(() => {
            this.recordList();
          }, 2000)
          this.$toast.clear();
        }).catch((err)=>{
          this.$toast.clear();
        })
      },
      handleSubmit(){
        this.visibility = false;
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .container{
    width: 100%;
    height: 100%;
    background-color: #F7F7F7;

    .container-content{
        padding-top: 60px;
        width: 100%;
        height: 100%;
    }
  }

  .content-home-list{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .home-list-item{
        margin-bottom: 30px;
        width: 85%;
        padding: 20px;
        border-radius: 16px;
        background: #FFF;
        box-shadow: 0px 1.744px 3.488px 0px rgba(0, 0, 0, 0.15);
        display: flex;
        flex-direction: column;

        .item-top{
            display: flex;
            justify-content: space-between;
            align-items: center;
            .top-title{
                img{
                    width: 72px;
                    height: 72px;
                    margin-right: 20px;
                }
                span{
                    color: #000;
                    font-size: 26px;
                }
            }
            .top-price{
                span{
                    color: #000;
                    font-size: 26px;
                }
            }
        }
        .item-bottom{
            margin-top: 30px;
            display: flex;
            justify-content: space-between;
            align-items: end;

            .bottom-time{
                display: flex;
                flex-direction: column;
                color: #000;
                font-size: 26px;
                span{
                    padding: 10px 0;
                }
            }
            .bottom-btn{
                button{
                    margin-bottom: 10px;
                    width: 100px;
                    height: 48px;
                    background-color: transparent;
                    border-radius: 4px;
                    border: 1px solid #FFB21B;
                    color: #FFB21B;
                    font-size: 26px;
                }
            }
        }
    }
  }

  .orderNodata{
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
		color: #999999;
	}
	.orderNodata img{
		width: 354px;
		margin-top: 170px;
	}
  </style>